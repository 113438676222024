<template>
  <main class="user-info-wrapper">
    <section class="u-picture-wrapper">
      <!-- <img
        :src="user.user_pic"
        :alt="`${user.first_name} ${user.last_name}`"
        v-if="user.user_pic"
      />
      <div class="u-picture" v-else>
        <span class="circle"></span>
        <p class="-xxxltb">
          {{ getNameInitials(user.first_name, user.last_name) }}
        </p>
      </div> -->
      <img
        :src="getUserPicture"
        :alt="`${user.first_name} ${user.last_name}`"
      />
    </section>
    <section class="u-info">
      <p class="-ltb -bold">{{ user.first_name }} {{ user.last_name }}</p>
      <p class="-ltb -bold" v-if="getUserDetails.comp_name">
        {{ getUserDetails.comp_name }}
      </p>

      <p class="-stb -bold" v-if="getUserDetails.profession">
        {{ getUserDetails.profession }}
      </p>
      <p
        class="-xstr"
        style="color: var(--warn); margin-bottom: 8px"
        v-if="!getUserDetails.comp_name || !getUserDetails.profession"
      >
        Don't forget to customize your personal information
        <span class="link" @click="goTo('UserProfile')">here</span>.
      </p>

      <div class="aditional-info1">
        <p class="-xstb">
          <i class="i-brickwall"></i> {{ projectsLength }} Projects
        </p>
        <p class="-xstb"><i class="i-ruler"></i> {{ unitsCount }} Units</p>
      </div>
      <div class="aditional-info2">
        <p class="-xstb" v-if="getUserDetails.country">
          <i class="i-pin"></i>{{ getUserDetails.country }}
        </p>
        <p class="-xstb" v-else><i class="i-pin"></i>Location not set</p>
        <p class="-xstb c-btn">Edit Profile</p>
      </div>
    </section>
  </main>
</template>

<script>
import { mapGetters } from "vuex";
import { getNameInitials } from "../../helpers/getNameInitials";
import { getUserPic } from "../../helpers/getUserPic";
export default {
  props: ["projectsLength", "unitsCount", "userDetails"],
  data() {
    return {
      userThumbnail:
        'background-image: url("../../assets/thumbnails/user-thumbnail.svg");',
    };
  },
  methods: {
    goTo(routeName) {
      this.$router.push({ name: routeName });
    },
    getNameInitials(name1, name2) {
      return getNameInitials(name1, name2);
    },
  },
  computed: {
    ...mapGetters(["user"]),
    getUserDetails() {
      return !this.userDetails ? false : this.userDetails;
    },
    getUserPicture() {
      return getUserPic();
    },
  },
};
</script>

<style scoped>
.user-info-wrapper {
  display: flex;
  flex-flow: column;
  gap: 1rem;
  width: 240px;
}
.u-picture-wrapper {
  position: relative;
  height: 200px;
  width: 100%;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 4px;
  background: rgb(235, 248, 255);
  background: linear-gradient(
    0deg,
    rgba(235, 248, 255, 1) 0%,
    rgba(255, 255, 255, 1) 68%
  );
}
.u-picture-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: inherit;
}
.u-picture {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  background: var(--secondary-highlight);
}
.u-picture .circle {
  position: absolute;
  width: 40%;
  height: 100%;
  transform: skew(-20deg);
  border-radius: 2px;
  background: var(--white1);
  z-index: 0;
}
.u-picture .circle::before {
  position: absolute;
  width: 80px;
  height: 80px;
  border-radius: 2px;
  background: var(--white1);
  z-index: 0;
}
.u-picture p {
  position: relative;
  font-size: 120px;
  color: var(--primary);
  z-index: 1;
}
.u-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}
.aditional-info1,
.aditional-info2 {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  padding-top: 5px;
}
.c-btn {
  cursor: pointer;
  user-select: none;
  color: var(--primary);
  /* background-image: linear-gradient(var(--info) 0%, var(--info) 100%); */
  background-position: right -100% bottom 0;
  background-size: 200% 1px;
  background-repeat: no-repeat;
}
.c-btn:hover {
  color: var(--info);
  background-image: linear-gradient(var(--info) 0%, var(--info) 100%);
  background-position: left -110% bottom 0;
  transition: background-position 800ms ease;
}
p {
  text-align: left;
}

i {
  width: 16px;
  height: 16px;
  margin-right: 0.5em;
  transform: scale(1);
}

@media screen and (max-width: 1024px) {
  .user-info-wrapper {
    width: 340px;
  }
  .u-info {
    width: 100%;
    justify-content: space-evenly;
  }
}
@media screen and (max-width: 470px) {
  .u-picture {
    min-width: 170px;
  }
}
</style>