<template>
  <main class="projects-list-container" v-if="projects.length">
    <ProjectsList :projects="projects" @deleteProject="deleteProject" />
  </main>
  <main class="projects-list-container" v-else>
    <img src="../../assets/thumbnails/no-project.svg" alt="Loading..." />
  </main>
</template>

<script>
import ProjectsList from "../projects/ProjectsList.vue";
export default {
  components: {
    ProjectsList,
  },
  props: ["projects"],
  emits: ["deleteProject"],
  methods: {
    deleteProject(id) {
      this.$emit("deleteProject", id);
    },
  },
};
</script>

<style scoped>
.projects-list-container {
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-flow: column;
  gap: 2rem;
  padding-inline: 1rem;
}
.projects-list-container img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  pointer-events: none;
  user-select: none;
}
@media screen and (max-width: 719px) {
  .projects-list-container {
    padding: 0;
  }
}
</style>